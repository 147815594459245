import React from 'react';
import Chart from 'chart.js/auto';

import { formatDateTimeLocal, formatDateDisplay, secondsToTime, getPercentChange, numberToLocal, numberToPrice } from '../../helpers/uiFunctions';
import { Statistic, ReportHeading } from '../../helpers/uiComponents';
import CallWindow from './callsComponents/callWindow';
import { InputRow } from '../../helpers/inputRow';
import { SearchForm } from '../../helpers/search';

class CallTracking extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
			name: null,
			allCalls: [],
			calls: [],
			count: 0,
			totals: [],
			oldCount: 0,
			sortVal: 0,
			tabName: "All",
        	currentPage: 1,
			leadsPerPage: 25,
			loading: true,
			editId: null,
			shareCall: false,
			editDetails: false,
			editValue: false,
			editName: false,
			affiliateCode: null,
			retailerList: null,
        };
        this.changePage = this.changePage.bind(this);
        this.changeLeadCount = this.changeLeadCount.bind(this);
        this.loadRetailer = this.loadRetailer.bind(this);
		this.close = this.close.bind(this);
		this.save = this.save.bind(this);
    }
	componentDidMount() {
		try {
			if (this.props.calls) {
				this.handleData(
					this.props.currentClient.name,
					this.props.retailer,
					this.props.affiliateCode,
					this.props.calls,
					this.props.oldCalls
				);
			}
		} catch (error) {
			console.log(error);
		}
	}
    componentDidUpdate(prevProps) {
		try{
			if (this.props.calls && (this.props.calls !== prevProps.calls)  && !this.props.calls.message) {
				this.handleData(
					this.props.currentClient.name,
					this.props.retailer,
					this.props.affiliateCode,
					this.props.calls,
					this.props.oldCalls
				);
			}
		} catch (error) {
			console.log(error);
		}
	}
	handleData(clientName, retailer, affiliateCode, calls, oldCalls) {
		if (!affiliateCode) {
			this.setState({
				name: clientName,
				calls: calls.calls.filter(call => { return call.conversionStatus === null}),
				allCalls: calls.calls,
				count: calls.count,
				oldCount: oldCalls.count,
				loading: false,
			});
			setTimeout(() => {
				this.buildChart(calls.daily);
			}, 500);
		} else if (retailer) {
			this.setState({
				name: clientName,
				calls: calls.calls,
				allCalls: calls.calls,
				count: calls.count,
				oldCount: oldCalls.count,
				loading: false,
			})
			setTimeout(() => {
				this.buildChart(calls.daily);
			}, 500);
		}
		this.getTotals(calls.calls);
	}
	getTotals(calls) {
		var arr = []
		arr["All"] = calls.length;
		arr["New"] = calls.filter((obj) => obj.conversionStatus === null).length;
		arr["Qualified"] = calls.filter((obj) => obj.conversionStatus === "Qualified").length;
		arr["Opportunity"] = calls.filter((obj) => obj.conversionStatus === "Opportunity").length;
		arr["Won"] = calls.filter((obj) => obj.conversionStatus === "Won").length;
		arr["Lost"] = calls.filter((obj) => obj.conversionStatus === "Lost").length;
		arr["Disqualified"] = calls.filter((obj) => obj.conversionStatus === "Disqualified").length;


		var leadValue = 0;
		var wonValue = 0;
		calls.forEach(function(call) {
			leadValue = leadValue + call.value;

			if (call.conversionStatus === "Won") {
				wonValue = wonValue + call.value;
			}
		});
		arr["leadValue"] = leadValue;
		arr["wonValue"] = wonValue;


		this.setState({
			totals: arr,
		});
		// console.log(arr);
	}

	buildChart(daily) {
		if (daily) {
			var data = [];
			var options = [];

			for (var i = daily.length - 1; i >= 0; i--) {
				data.push(daily[i].count);
				options.push(formatDateDisplay(daily[i].date));
			}
			data.reverse(); options.reverse();

			var ctx = document.getElementById('graph-calls-by-day');
			new Chart(ctx, {
				"type":"line",
				"data": {
					"labels":options,
					"datasets":[
						{
							data:data,
							backgroundColor: "rgba(72, 152, 187, 0.3)",
							borderColor: "rgba(72, 152, 187, 1)",
							pointBackgroundColor: "rgba(190, 209, 47, 1)",
							pointRadius: 5,
							fill: "start",
						}
					]
				},
				options: {
					plugins: {
						aspectRatio: 1,
						maintainAspectRatio: false,
						legend: {
							display: false,
						},
						title: {
							display: true,
							text: "Calls By Day",
							fontSize: 16,
						},
						
					},
					layout: {
						padding: {
							left: 50,
							right: 50,
							top: 25,
							bottom: 25
						}
					},
					scales: {
						xAxes: [{
							stacked: true
						}],
						yAxes: [{
							stacked: true
						}]
					},
					animations: {
						linear: {
							duration: 1000,
							easing: 'linear',
							from: 1,
							to: 0,
							loop: true
						}
					},
				}
			});
		}
	}

	showDetails(id) {
		if(id === this.state.editId) {
			this.close();
		} else {
			this.setState({ editDetails: true, editId: id })
			console.log("show firing")
		}
	}
	close() {
		console.log("close firing")
		this.setState({ editDetails: false, editId: null }, () => {
			console.log(this.state);
		});
	}
	save(id) {
		// get leads to update later with response data
		var newCalls = this.state.calls;
		var newAllCalls = this.state.allCalls;
		var callsIndex = newCalls.findIndex(call => call['Call ID'] === id);
		var allCallsIndex = newAllCalls.findIndex(call => call['Call ID'] === id);
		// get values from form
		var status = document.getElementById("status-"+id).value;
		var detail = document.getElementById("detail-"+id).value;
		var name = document.getElementById("name-"+id).value;
			if(!name) {
				name = "";
			}
		var value = document.getElementById("value-"+id).value;
			if(!value) {
				value = 0;
			}
			value = parseFloat(value).toFixed(2);
		// set fetch variables
		var params = {
			headers: {"authorization": "bearer " + this.props.token},
			method: "POST",
		}
		// fetchURLs
		var statusURL = this.props.endpoints.serviceCalls+"status/"+id+"/"+status
		if(detail) {
			statusURL = statusURL+"?detail="+detail
		}
		var valueURL = this.props.endpoints.serviceCalls+"value/"+id+"/"+value
		var nameURL = this.props.endpoints.serviceCalls+"name/"+id+"/"+name


		// Fetches
		if (name) {
			fetch(nameURL, params)
			.then(response => {
				if (response.ok) { return response.json(); } 
				else { throw new Error('Something went wrong setting the name...'); }
			})
			.then(data => {
				console.log(data);
				// do stuff with data
				if (data.success) {
					newAllCalls[allCallsIndex]['name'] = data.result.name;
					newCalls[callsIndex]['name'] = data.result.name;
				}
				this.setState({
					calls: newCalls,
					allCalls: newAllCalls,
				})
			})
			.catch(error => console.log(error));
		}
		fetch(statusURL, params)
		.then(response => {
			if (response.ok) { return response.json(); } 
			else { throw new Error('Something went wrong setting the status...'); }
		})
		.then(data => {
			console.log(data);
			// do stuff with data
			if (data.success) {
				newAllCalls[allCallsIndex]['conversionStatus'] = data.result.conversionStatus;
				newAllCalls[allCallsIndex]['conversionDetail'] = data.result.conversionDetail;
				newAllCalls[allCallsIndex]['workflow'] = data.result.workflow;
				newCalls[callsIndex]['conversionStatus'] = data.result.conversionStatus;
				newCalls[callsIndex]['conversionDetail'] = data.result.conversionDetail;
				newCalls[callsIndex]['workflow'] = data.result.workflow;
			}
			return fetch(valueURL, params)
		})
		.then(response => {
			if (response.ok) { return response.json(); } 
			else { throw new Error('Something went wrong setting the value...'); }
		})
		.then(data => {
			console.log(data);
			// do stuff with data
			if (data.success) {
				newAllCalls[allCallsIndex]['value'] = data.result.value;
				newCalls[callsIndex]['value'] = data.result.value;
			}
			this.setState({
				calls: newCalls,
				allCalls: newAllCalls,
			}, () => {
				this.getTotals(newAllCalls);
			}); 
		})
		.catch(error => console.log(error));
	}

	sortBy(prop) {
		var list = this.state.calls;
		var sort = this.state.sortVal;
		if (sort === 0 || sort === 2) {
			list = list.sort((a, b) => (a[prop] > b[prop]) ? 1 : -1);
			sort = 1;
		} else {
			list = list.sort((a, b) => (a[prop] < b[prop]) ? 1 : -1);
			sort = 2;
		}
		this.setState({
			calls: list,
			sortVal: sort,  // up or down
		});
	}
	toggleTabs(status, i) {
		var newCalls = this.state.allCalls;
		var menuItems = document.querySelectorAll('#metrics-calls .tab-menu .tab');
		
		for (var x = menuItems.length - 1; x >= 0; x--) {
			menuItems[x].classList.remove('active');
			menuItems[i-1].classList.add('active');
		}

		if (status === "All") {
			// silence is golden
		} else if (status === "New") {
			newCalls = newCalls.filter(call => { return call.conversionStatus === null});
		} else {
			newCalls = newCalls.filter(call => { return call.conversionStatus === status});
		}

		this.setState({
			calls: newCalls,
			tabName: status,
		});
	}

	changePage(e) {
		this.setState({
			currentPage: Number(e.target.id),
		});
	}
	changeLeadCount(e) {
		this.setState({
			leadsPerPage: e.target.options[e.target.selectedIndex].value,
			currentPage: 1,
		});
	}

	loadRetailer(e) {
		this.setState({loading: true});
		var affiliateCode = e.target.value;
		var clientCode = this.props.currentClient.clientCode;
		var changeClient = this.props.changeClient;

		changeClient(clientCode, affiliateCode);
	}
	clearRetailer() {
		this.setState({loading: true});
		var affiliateCode = null;
		var clientCode = this.props.currentClient.clientCode;
		var changeClient = this.props.changeClient;
		document.getElementById("calls-retailer-list").selectedIndex = 0;

		changeClient(clientCode, affiliateCode);
	}
	

	render() {
		const {calls, count, oldCount, totals, leadsPerPage, currentPage, loading, editDetails, editId, name } = this.state;
		const hideHistorical = this.props.hideHistorical;
		const roles = this.props.roles;
		const isRetailer = this.props.isRetailer;
		const retailerList = this.props.retailerList;

		let currentLeads = calls;

		if (leadsPerPage !== "all") {
			let indexOfLastTodo = currentPage * leadsPerPage;
			let indexOfFirstTodo = indexOfLastTodo - leadsPerPage;
			currentLeads = calls.slice(indexOfFirstTodo, indexOfLastTodo);
		}

		const renderLeads = currentLeads.map((call, index) => {
			return (
				<React.Fragment key={call['Call ID']}>
					<li className={(call["conversionStatus"] === null) ? ( "New" ) : ( call["conversionStatus"] )} onClick={() => this.showDetails(call['Call ID'])}>
						<div>{formatDateTimeLocal(call['Call Started'])[0]}<br/>{formatDateTimeLocal(call['Call Started'])[1]}</div>						
						<div>{secondsToTime(call['Duration'])}</div>
						<div>{call['Caller Id']}</div>
						<div>{call['Tracking']}</div>
						<div>{call['Campaign Name']}</div>
						<div>{call["name"]}</div>
						<div>
							{(call["conversionStatus"] === null) ? (
								<span>New</span>
							) : (
								<span>{call["conversionStatus"]}</span>
							)}
						</div>
						<div>{numberToPrice(call["value"])}</div>
					</li>
					{(editDetails === true && editId === call['Call ID']) && (
						<CallWindow
							call={call}
							save={this.save}
							close={this.close}
							endpoints={this.props.endpoints}
							token={this.props.token}	
						/>
					)}
				</React.Fragment>
			);
		});

		const pageNumbers = [];
		for (let i = 1; i <= Math.ceil(calls.length / leadsPerPage); i++) {
			pageNumbers.push(i);
		}

		const renderPageNumbers = pageNumbers.map(number => {
			return (
				(number === this.state.currentPage) ? (
					<li key={number} id={number} onClick={this.changePage} className="active" >
						{number}
					</li>
				) : (
					<li key={number} id={number} onClick={this.changePage} >
						{number}
					</li>
				)
			);
		});

		return(
			(this.props.currentClient) && (
				<div id="metrics-calls" className="report calls">
					<ReportHeading
						title="Phone Calls"
						name={name}
						dates={this.props.dates}
						hideHistorical={hideHistorical}
					/>

					<button className="white download-report" onClick={() => this.props.downloadReport("calls")}>Download Calls Report</button>

					{( (isRetailer === false) && (retailerList) && (roles.includes("portalAdm") || roles.includes("portalVlctyMngr")) ) && (
        				<div className="retailer-selector">
	            			Retailer:
	            			<span className="clear-client"><i className="far fa-window-close" onClick={() => this.clearRetailer()}></i></span>
	            			<InputRow type="select" id="calls-retailer-list" property="retailerList" options={retailerList} onChange={this.loadRetailer} />
	            		</div>
            		)}

					{calls.message ? ( <p>{calls.message}</p> ) : (
						<React.Fragment>
							<section className="stats row">
								<Statistic
									label="Total Calls"
									value={numberToLocal(count)}
									status={getPercentChange(count, oldCount)[1]}
									percent={getPercentChange(count, oldCount)[0]}
									hideHistorical={hideHistorical}
								/>
								<Statistic
									label="Average Call Length"
									value={secondsToTime(this.props.avgDuration)}
									status={getPercentChange(this.props.avgDuration, this.props.oldDuration)[1]}
									percent={getPercentChange(this.props.avgDuration, this.props.oldDuration)[0]}
									hideHistorical={hideHistorical}
								/>
								<Statistic
									label="Total Lead Value"
									value={numberToPrice(totals["leadValue"])}
									
									hideHistorical={"true"}
								/>
								<Statistic
									label="Total Lead Value Won"
									value={numberToPrice(totals["wonValue"])}
									
									hideHistorical={"true"}
								/>
							</section>

							{(this.props.calls && this.props.calls.count > 0) && (
								<section className="stats-graph">
									<div className="graph wide">
										<div className="graph-container">
											<canvas className="chart" id="graph-calls-by-day" />
										</div>
									</div>
								</section>
							)}

							{(loading === true) && (
		            			<div className="report-loading">
		            				<p><i className="fas fa-circle-notch"></i></p>
		            			</div>
		            		)}

							<div className="tab-menu">
								<div onClick={() => this.toggleTabs("New", 1)} className="tab New active">New ({totals["New"]})</div>
								<div onClick={() => this.toggleTabs("Qualified", 2)} className="tab Qualified">Qualified ({totals["Qualified"]})</div>
								<div onClick={() => this.toggleTabs("Opportunity", 3)} className="tab Opportunity">Opportunity ({totals["Opportunity"]})</div>
								<div onClick={() => this.toggleTabs("Won", 4)} className="tab Won">Won ({totals["Won"]})</div>
								<div onClick={() => this.toggleTabs("Lost", 5)} className="tab Lost">Lost ({totals["Lost"]})</div>
								<div onClick={() => this.toggleTabs("Disqualified", 6)} className="tab Disqualified">Disqualified ({totals["Disqualified"]})</div>
								<div onClick={() => this.toggleTabs("All", 7)} className="tab All">All Leads ({totals["All"]})</div>
							</div>
							<section className="calls-list">
								{/* <div className="pagination">
									<ul className="page-numbers">
										{renderPageNumbers}
									</ul>
								</div> */}

								<SearchForm page="calls" />
								<div className='table_wrapper_inner'>
								<div className="table-heading">
									<div className="sortable" onClick={() => this.sortBy("Call Started")}>Date  <span><i className="fas fa-sort"></i></span></div>
									<div className="sortable" onClick={() => this.sortBy("Duration")}>Duration <span><i className="fas fa-sort"></i></span></div>
									<div className="sortable" onClick={() => this.sortBy("Caller Id")}>Caller Number <span><i className="fas fa-sort"></i></span></div>
									<div className="sortable" onClick={() => this.sortBy("Tracking")}>Tracking Number <span><i className="fas fa-sort"></i></span></div>
									<div className="sortable" onClick={() => this.sortBy("Campaign Name")}>Campaign Name <span><i className="fas fa-sort"></i></span></div>
									<div className="sortable" onClick={() => this.sortBy("Name")}>Caller Name <span><i className="fas fa-sort"></i></span></div>
									<div className="sortable" onClick={() => this.sortBy("conversionStatus")}>Lead Status <span><i className="fas fa-sort"></i></span></div>
									<div>Lead Value</div>
								</div>
								<ul className="table-body">
									{renderLeads}
								</ul>
								</div>
								<div className="pagination">
									<ul className="page-numbers">
										{renderPageNumbers}
									</ul>
								</div>
							</section>
						</React.Fragment>
					)}
				</div>
			)
		)
	}

}

export default CallTracking;